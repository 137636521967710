@import url("https://fonts.googleapis.com/css2?family=Noto+Emoji&family=Noto+Sans+Malayalam:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

/* Global variables */
:root {
  --clr-white: #ffffff;
  --opq-white-30: #ffffff30;
  --opq-orange-20: #ff5a3620;
  --opq-green-20: #00ff0020;
  --clr-gray1: #f7f7f7;
  --clr-gray2: #efefef;
  --clr-gray3: #363d44;
  --opq-gray3-20: #363d4420;
  --opq-gray3-60: #363d4460;
  --clr-gray4: #1e1e1e;
  --opq-gray4-50: #1e1e1e50;
  --clr-black: #000000;
  --opq-black-5: #00000005;
  --opq-black-10: #00000010;
  --opq-black-50: #00000050;
  --opq-black-80: #00000080;
}

/* Universal styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Root body element styles */
body {
  background-color: var(--clr-gray1);
  color: var(--clr-black);
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
}
details summary {
  transition: 300ms;
}

main {
  flex: 1 1;
}

h1 {
  font-size: 2.5rem;
}

/* Styles for all h2 elements */
h2 {
  font-size: 1.5rem;
}

/* Styles for all h3 elements */
h3 {
  font-size: 1rem;
}

/* Styles for all button elements */
button {
  border: none;
}

/* Styles for all textarea elements */
textarea {
  border: none;
  outline: none;
}

/* Responsive styles for laptop screen */
@media screen and (min-width: 1024px) {
  body {
    font-size: 1rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.375rem;
  }
}

@media screen and (min-width: 1920px) {
  h2 {
    font-size: 2.5rem;
  }
}

/* Styles for the suggestions box and its children created by the transliteration js bundle */

/* Reset all the margins, paddings, text color */
.ks-input-suggestions,
.ks-input-suggestions * {
  margin: 0 !important;
  padding: 0 !important;
  color: var(--clr-black) !important;
  font-size: 1rem;
}
@media screen and (min-width: 768px) {
  .odiaTop {
    grid-column: span 5;
  }
  .odiaBottom1 {
    grid-column: span 5;
    justify-self: center !important;
  }
  .odiaBottom2 {
    grid-column: span 10;
    justify-self: center !important;
  }
}
@media screen and (min-width: 1090px) {
  .odiaTop {
    grid-column: span 4;
  }
  .odiaBottom1 {
    grid-column: span 6;
    justify-self: end !important;
  }
  .odiaBottom2 {
    grid-column: span 6;
    justify-self: start !important;
  }
}
/* The whole suggestions list box */
.ks-input-suggestions {
  background-color: var(--clr-white) !important;
  border: 1px solid var(--opq-black-10) !important;
  border-radius: 8px !important;
  padding: 8px !important;
  position: absolute !important;
  box-shadow: none !important;
  min-width: 0px !important;
}

/* The input box where the currently typing keys appear */
.suggestion-input {
  padding: 8px !important;
  font-weight: 600;
  font-size: 1rem;
  outline: none;
}

/* Each suggestion elements */
.suggestion-div {
  padding: 8px !important;
  border-radius: 6px !important;
}

/* A suggestion element when it's getting hovered */
.suggestion-div:hover {
  background-color: var(--opq-black-5) !important;
}

/* A suggestion element when it is currently active */
.suggestion-div.active {
  background-color: var(--opq-black-10) !important;
}

/* A loader showing when the API is being fetched */
.loading-box {
  display: none;
}

/* Hide textarea placeholder when typing is started */
body:has(.ks-input-suggestions:not(.hidden)) .textarea::placeholder {
  color: transparent;
}

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100svh;
  font-family: "Roboto", sans-serif;
}
.not-found p {
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--clr-gray3);
  margin-top: 1rem;
}

.editor-divider {
  width: 100%;
  height: 1px;
  background-color: #e9eaeb;
}

